import React from 'react';
import styled from 'styled-components';

import Layout from 'layout/Layout';
import Seo from 'components/Seo';
import { breakpoints } from 'lib/constants';
import { pxToRem } from 'lib/utils';

const StyledWrapper = styled.div`
  margin-top: 30vh;
`;

const StyledHeading = styled.h1`
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: ${pxToRem(breakpoints.large)}) {
    font-size: ${pxToRem('26px')};
    letter-spacing: ${pxToRem('2px')};
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    font-size: ${pxToRem('34px')};
    letter-spacing: ${pxToRem('3px')};
  }
`;

const StyledText = styled.div`
  font-size: ${pxToRem('14px')};
  text-align: center;
`;

const NotFoundPage = () => (
  <Layout>
    <Seo title="Pagina non trovata" />
    <StyledWrapper>
      <StyledHeading>Pagina non trovata</StyledHeading>
      <StyledText>
        <p>
          La pagina cercata non esiste; <a href="/">clicca qui per tornare alla Home Page</a>
        </p>
      </StyledText>
    </StyledWrapper>
  </Layout>
);

export default NotFoundPage;
